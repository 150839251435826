import { useContext } from "react";
import { WizardContext } from "./store/wizard.store";
import { WizardBookingWithCode } from "./WizardBookingWithCode";

export const WizardTreatmentType: React.VFC<WizardTreatmentTypeProps> = (props) => {
  let { setWizardFormData } = useContext(WizardContext);

  const onClickTreatmentType = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value, name } = e.currentTarget;

    setWizardFormData((prevState) => ({
      ...prevState,
      selectedTreatmentType: { id: parseInt(value), description: name, content: "" },
      activePage: prevState.activePage + 1
    }));
  };

  return (
    <div className="grid g-col-12">
      <title>Välj behandlingstyp</title>
      <div className="g-col-12" dangerouslySetInnerHTML={{ __html: props.infoTextTreatmentTypes }}></div>
      {props.treatmentTypes.map((treatmentType) => {
        return (
          <div className="g-col-12 g-col-lg-10 expanded-block ftvwebbooking__expanded-block" key={treatmentType.id}>
            <details className="expandable-content expanded-block__details">
              <summary className="expanded-block__heading">
                <h2>{treatmentType.description}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: props.chevronIconSvg }}
                ></div>
              </summary>
              <div className="expanded-block__content" dangerouslySetInnerHTML={{ __html: treatmentType.content }} />
              <div className="ftvwebbooking__expanded-block-button">
                <button
                  type="button"
                  className="button-primary link-focus"
                  value={treatmentType.id}
                  name={treatmentType.description}
                  onClick={onClickTreatmentType}
                >
                  Välj
                </button>
              </div>
              <div data-minimize="" className="expanded-block__minimize">
                <button
                  type="button"
                  className="link-focus"
                  aria-controls="expandable-content-1074770"
                >
                  Minimera
                  <div
                    dangerouslySetInnerHTML={{ __html: props.chevronIconSvg }}
                  ></div>
                </button>
              </div>
            </details>
          </div>
        );
      })}
      <div className="g-col-12 g-col-lg-10 expanded-block ftvwebbooking__expanded-block">
        <details className="expandable-content expanded-block__details">
          <summary className="expanded-block__heading">
            <h2>{props.ftvOnlineTitle}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: props.chevronIconSvg }}
            ></div>
          </summary>
          <div className="expanded-block__content" dangerouslySetInnerHTML={{ __html: props.infoTextFtvOnline }} />
          <div className="ftvwebbooking__expanded-block-button">
            <a
              type="button"
              className="button-primary link-focus"
              target="_blank"
              href={props.ftvOnlineLink}>
              Gå vidare
            </a>
          </div>
          <div data-minimize="" className="expanded-block__minimize">
            <button
              type="button"
              className="link-focus"
              aria-controls="expandable-content-1074770"
            >
              Minimera
              <div
                dangerouslySetInnerHTML={{ __html: props.chevronIconSvg }}
              ></div>
            </button>
          </div>
        </details>
      </div>
      <WizardBookingWithCode
        infoTextBookingCode={props.infoTextBookingCode}
        chevronIconSvg={props.chevronIconSvg}
        titleBookingCode={props.titleBookingCode}
      />
    </div >
  );
};