export const validateWizardFormData = (formData: WizardFormData, id?: string): Record<string, string> => {
    const errors: Record<string, string> = { ...formData.validationErrors };

    const personalNumberRegEx = /^(\d{6}|\d{8})[-|(\s)]{0,1}\d{4}$/;
    const eMailRegEx = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/;
    const phoneNumberRegEx = /^[0]{1}[7]{1}[0-9]{8}$/;

    //First validate that the field is not empty
    if (!formData[id] && id) errors[id] = "Fältet är obligatoriskt";
    //Then validate the individual conditions
    else if (errors[id]) delete errors[id];
    else if (id === "personalNumber" && !personalNumberRegEx.test(formData[id])) errors[id] = "Fältet måste innehålla ett giltigt personnummer";
    else if (id === "personalNumber" && parseInt(formData[id].substring(0, 4)) > new Date().getFullYear() - 24) errors[id] = "Bokning via webben får endast göras av personer som är 24 år eller äldre, vänligen kontakta annars kliniken på telefon för att boka tid.";
    else if (id === "eMail" && !eMailRegEx.test(formData[id])) errors[id] = "Fältet måste innehålla en giltig epost";
    else if (id === "mobilePhonenumber" && !phoneNumberRegEx.test(formData[id])) errors[id] = "Fältet måste innehålla ett giltigt telefonnummer";
    else if (!id) {
        if (!formData["getInformationFromSkatteverket"]) errors["getInformationFromSkatteverket"] = "Det är obligatoriskt att hämta personuppgifter från Skatteverket. Om du inte vill det, vänligen kontakta kliniken på 1177 eller telefon för att boka tid.";
        else delete errors["getInformationFromSkatteverket"];
    }

    return errors;
}