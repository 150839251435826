import { MainMenuProps } from './types';
import { TopMenuItem } from './TopMenuItem';

export const MainMenu: React.VFC<MainMenuProps> = ({
  menuItems,
  startPage,
  startPageIsCurrentPage,
  chevronIconSvg,
  arrowIconSvg,
}) => {
  return (
    <nav className="container main-menu"
      data-testid="mainmenu">
      <ul className="grid">
        {startPage != null && (
          <li>
            <a
              href={startPage.href}
              className={
                startPageIsCurrentPage
                  ? 'main-menu__top-item ignore-link-focus main-menu__top-item--selected'
                  : 'main-menu__top-item ignore-link-focus'
              }>
              {startPage.text}
            </a>
          </li>
        )}

        {menuItems.map((item) => (
          <TopMenuItem key={item.pageId} menuItem={item} chevronIconSvg={chevronIconSvg} arrowIconSvg={arrowIconSvg} />
        ))}
      </ul>
    </nav>
  );
};
