import { useContext, useEffect } from "react";
import { WizardContext } from "./store/wizard.store";
import * as signalR from "@microsoft/signalr";

export const WizardSearchResult: React.VFC<WizardSearchResultProps> = (props) => {
    let { wizardFormData, setWizardFormData } = useContext(WizardContext);

    const connection = new signalR.HubConnectionBuilder()
        .withUrl("/ftvBookingHub")
        .build();

    useEffect(() => {
        return () => {
            connection.stop();
        }
    }, [])

    const onClickSelectTimeSlot = (timeSlot: TimeSlot, performerName: string) => {
        setWizardFormData((prevState) => ({ ...prevState, selectedTimeSlot: timeSlot, performerName: performerName, activePage: prevState.activePage + 1 }));
    }

    const onClickGetMore = async () => {
        const request: WizardSearchRequest = {
            currentPageId: wizardFormData.currentPageId,
            selectedClinics: wizardFormData.selectedClinics.map(x => x.value),
            selectedDate: wizardFormData.selectedDate,
            SelectedTreatmentType: wizardFormData.selectedTreatmentType,
            skip: props.skip + 10
        };

        props.setIsLoading(true);

        await connection.start().then(async () => {
            connection.send("getMoreTimes", request);
            props.setSkip(props.skip + 10);
        }).catch((err) => console.error(err));

        connection.on("moreTimesRecieved", (response: TimeSlotResponse) => {
            if (response && !response.errorMessage && response.timeSlots && response.timeSlots.length > 0) {
                setWizardFormData((prevState) => ({ ...prevState, searchResult: [...wizardFormData.searchResult, ...response.timeSlots] }));
            } else if (response && response.errorMessage) {
                props.setErrorHeader(response.errorHeader);
                props.setErrorMessage(response.errorMessage);
            } else {
                setWizardFormData((prevState) => ({ ...prevState, searchResult: null }))
            }
            props.setIsLoading(false);
        });
    }

    const onClickGetMoreForBookingCode = async () => {
        const request: WizardSearchForBookingCodeRequest = {
            currentPageId: wizardFormData.currentPageId,
            selectedDate: wizardFormData.selectedDate,
            skip: props.skip + 10,
            bookingCode: wizardFormData.bookingCode,
            personalNumber: wizardFormData.personalNumber
        };
        props.setIsLoading(true);

        await connection.start().then(async () => {
            connection.send("getTimesForBookingCode?vgrform=1", request);
        }).catch((err) => console.error(err));

        connection.on("timesReceivedForBookingCode", (response: TimeSlot[]) => {
            if (response && response.length > 0) {
                setWizardFormData((prevState) => ({ ...prevState, searchResult: [...prevState.searchResult, ...response] }));
            } else {
                setWizardFormData((prevState) => ({ ...prevState, searchResult: null }))
            }
            props.setIsLoading(false);
        });
    }

    const displayNameFacility = (timeSlot: TimeSlot) => {
        const foundAdditionalClinicInformation = props.additionalClinicInformation.find(x => x.multiCheckboxItem.checkboxValue == timeSlot.healthcareFacilityId);

        if (foundAdditionalClinicInformation) return foundAdditionalClinicInformation.multiCheckboxItem.checkboxText;
        else return timeSlot.healthcareFacilityName.replace("Folktandvården", "");
    }

    const linkFacility = (timeSlot: TimeSlot) => {
        const foundAdditionalClinicInformation = props.additionalClinicInformation.find(x => x.multiCheckboxItem.checkboxValue == timeSlot.healthcareFacilityId);

        return foundAdditionalClinicInformation.clinicLink;
    }

    return (
        <>
            {props.activateLastMinute && <div className="grid g-col-12 ftvwebbooking__search-results-wrapper--dark">
                <div className="g-col-12 ftvwebbooking__search-results-wrapper-header">
                    <div dangerouslySetInnerHTML={{ __html: props.lastMinuteResultIcon }}></div>
                    <strong>Sista minuten</strong>
                    <div className="g-col-12" dangerouslySetInnerHTML={{ __html: props.infoTextLastMinuteSearchResult }} />
                </div>
                <div className="grid g-col-12 ftvwebbooking__search-results last-minute">
                    <div className="grid g-col-12 ftvwebbooking__search-results-header">
                        <div className="g-col-2">
                            <strong>Datum</strong>
                        </div>
                        <div className="g-col-2">
                            <strong>Tid</strong>
                        </div>
                        <div className="g-col-3">
                            <strong>Behandlare</strong>
                        </div>
                        <div className="g-col-3">
                            <strong>Klinik</strong>
                        </div>
                    </div>
                    {wizardFormData.lastMinuteSearchResult && Object.values(wizardFormData.lastMinuteSearchResult).some(x => x !== null && x !== '') &&
                        <div className="grid g-col-12 ">
                            <div className="grid g-col-12 ftvwebbooking__search-result--mobile">
                                <div className="g-col-9">
                                    {new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getDate() + "/" + (new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getMonth() + 1)},
                                    {' ' + new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getHours() + ":" + ('0' + new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getMinutes()).slice(-2)}<br />
                                    {wizardFormData.lastMinuteSearchResult.performerName}<br />
                                    {displayNameFacility(wizardFormData.lastMinuteSearchResult)}
                                </div>
                                <div className="g-col-3">
                                    <button className="button-primary" type="button" onClick={() => onClickSelectTimeSlot(wizardFormData.lastMinuteSearchResult, wizardFormData.lastMinuteSearchResult.performerName)}>Välj</button>
                                </div>
                            </div>
                            <div className="grid g-col-12 ftvwebbooking__search-result last-minute">
                                <div className="g-col-2">
                                    {new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getDate() + "/" + (new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getMonth() + 1)}
                                </div>
                                <div className="g-col-2">
                                    {new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getHours() + ":" + ('0' + new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getMinutes()).slice(-2)}
                                </div>
                                <div className="g-col-3">
                                    {wizardFormData.lastMinuteSearchResult.performerName}
                                </div>
                                <a href={linkFacility(wizardFormData.lastMinuteSearchResult)} className="g-col-3">
                                    {displayNameFacility(wizardFormData.lastMinuteSearchResult)}
                                </a>
                                <div className="g-col-2">
                                    <button className="button-primary" type="button" onClick={() => onClickSelectTimeSlot(wizardFormData.lastMinuteSearchResult, wizardFormData.lastMinuteSearchResult.performerName)}>Välj tid</button>
                                </div>
                            </div>
                        </div>
                    }
                    {props.isLoadingLastMinute &&
                        <div className="grid g-col-12 ftvwebbooking__search-results--loading"><span className="loader"></span></div>
                    }
                    {wizardFormData.lastMinuteSearchResult === null &&
                        <div className="grid g-col-12 ftvwebbooking__search-results-wrapper ftvwebbooking__search-results-wrapper--dark">
                            <p className="g-col-12">
                                Tyvärr finns det inga lediga tider. För att få förslag på tider kan du testa att söka på: annat datum, annan tid på dagen, en annan klinik eller ett annat område.
                            </p>
                        </div>
                    }
                </div>
            </div>}

            {!props.errorMessage ? <div className="grid g-col-12 ftvwebbooking__search-results-wrapper">
                <div className="g-col-12 ftvwebbooking__search-results-wrapper-header">
                    <div dangerouslySetInnerHTML={{ __html: props.searchResultIcon }}></div>
                    <strong>Sökträffar</strong>
                    <div className="g-col-12" dangerouslySetInnerHTML={{ __html: props.infoTextSearchResult }} />
                </div>
                <ul className="grid g-col-12 ftvwebbooking__search-results">
                    <li className="grid g-col-12 ftvwebbooking__search-results-header">
                        <div className="g-col-2">
                            <strong>Datum</strong>
                        </div>
                        <div className="g-col-2">
                            <strong>Tid</strong>
                        </div>
                        <div className="g-col-3">
                            <strong>Behandlare</strong>
                        </div>
                        <div className="g-col-3">
                            <strong>Klinik</strong>
                        </div>
                    </li>
                    {wizardFormData.searchResult && wizardFormData.searchResult.length > 0 && wizardFormData.searchResult.map(result => {
                        return (
                            <div className={props.relatedClinics.find(x => x.multiCheckboxItem.checkboxValue == result.healthcareFacilityId) ? "g-col-12 ftvwebbooking__search-results-wrapper--dark" : "g-col-12"} key={result.id}>
                                <div>
                                    <div className="g-col-12 ftvwebbooking__search-result--mobile">
                                        <li className="grid g-col-12">
                                            <div className="g-col-9">
                                                {new Date(Date.parse(result.startTime)).getDate() + "/" + (new Date(Date.parse(result.startTime)).getMonth() + 1)},
                                                {' ' + new Date(Date.parse(result.startTime)).getHours() + ":" + ('0' + new Date(Date.parse(result.startTime)).getMinutes()).slice(-2)}<br />
                                                {result.performerName}<br />
                                                <a href={linkFacility(result)}>{displayNameFacility(result)}</a>
                                            </div>
                                            <div className="g-col-3">
                                                <button className="button-primary" type="button" onClick={() => onClickSelectTimeSlot(result, result.performerName)}>Välj</button>
                                            </div>
                                        </li>
                                    </div>
                                    <div className="ftvwebbooking__search-result">
                                        <li className="grid g-col-12">
                                            <div className="g-col-2">
                                                {new Date(Date.parse(result.startTime)).getDate() + "/" + (new Date(Date.parse(result.startTime)).getMonth() + 1)}
                                            </div>
                                            <div className="g-col-2">
                                                {new Date(Date.parse(result.startTime)).getHours() + ":" + ('0' + new Date(Date.parse(result.startTime)).getMinutes()).slice(-2)}
                                            </div>
                                            <div className="g-col-3">
                                                {result.performerName}
                                            </div>
                                            <a href={linkFacility(result)} className="g-col-3">
                                                {displayNameFacility(result)}
                                            </a>
                                            <div className="g-col-2">
                                                <button className="button-primary" type="button" onClick={() => onClickSelectTimeSlot(result, result.performerName)}>Välj tid</button>
                                            </div>
                                        </li>
                                    </div>
                                </div>
                                <div className="ftvwebbooking__divider--mobile">
                                </div>
                            </div>
                        )
                    })}
                    {props.isLoading &&
                        <div className="grid g-col-12 ftvwebbooking__search-results--loading"><span className="loader"></span></div>
                    }
                    {wizardFormData.searchResult === null &&
                        <div className="grid g-col-12 ftvwebbooking__search-results-wrapper ftvwebbooking__search-results-wrapper--dark">
                            <p className="g-col-12">
                                Tyvärr finns det inga lediga tider. För att få förslag på tider kan du testa att söka på: annat datum, annan tid på dagen, en annan klinik eller ett annat område.
                            </p>
                        </div>
                    }
                </ul>
                <div className="g-col-12 ftvwebbooking__divider">
                </div>
                <div className="g-col-12 ftvwebbooking__view-more-wrapper">
                    <button type="button" className="g-start-6" onClick={props.isBookingWithCode ? onClickGetMoreForBookingCode : onClickGetMore}>Visa fler tider</button>
                </div>
            </div> : <div className="g-col-12 ftvwebbooking__search-results-wrapper"><h2>{props.errorHeader}</h2><div dangerouslySetInnerHTML={{ __html: props.errorMessage }}></div></div>}
        </>
    )
}