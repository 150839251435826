import React from 'react';
import ReactDOM from 'react-dom';
import * as Components from './components/index';
import ReactDOMServer from 'react-dom/server';
import 'whatwg-fetch';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';

global['React'] = React;
global['ReactDOM'] = ReactDOM;
global['ReactDOMServer'] = ReactDOMServer;
global['VGR'] = { 'Components': Components };

dayjs.locale('sv');