import React, { useState, useRef, useEffect } from 'react';
import { TopMenuItemProps } from './types';
import { SubMenuItems } from './SubMenuItems';

export const TopMenuItem: React.VFC<TopMenuItemProps> = ({ menuItem, chevronIconSvg, arrowIconSvg }) => {
  const [id, setId] = useState(Math.random().toString(16).slice(2));
  const [isSelected, setIsSelected] = useState(false);

  let ref = useRef<HTMLLIElement | null>();

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (isSelected && ref.current && !ref.current.contains(event.target as Node)) {
        setIsSelected(false);
      }
    };

    const handleTabOutside = (event: KeyboardEvent) => {
      if (event.key == 'Tab') {
        handleClickOutside(event);
      }
    };

    if (menuItem.hasChildren) {
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('keyup', handleTabOutside);
    }
    return () => {
      if (menuItem.hasChildren) {
        // Cleanup the event listener
        document.removeEventListener('click', handleClickOutside);
        document.removeEventListener('keyup', handleTabOutside);
      }
    };
  }, [isSelected]);

  async function onTopMenuItemClick(event) {
    event.preventDefault();

    setIsSelected((prev) => !prev);
  }

  const className = `main-menu__top-item ignore-link-focus ${menuItem.isInActiveChain ? 'main-menu__top-item--selected' : ''
    } ${isSelected ? 'main-menu__top-item--open' : ''}`;

  return (
    <li ref={ref}>
      {menuItem.hasChildren ? (
        <>
          <a
            className={className}
            href={menuItem.href}
            aria-expanded={isSelected ? 'true' : 'false'}
            role="button"
            aria-controls={id}
            onClick={(e) => {
              onTopMenuItemClick(e);
            }}
            data-haschildren="true">
            {menuItem.text}
            <div className="main-menu__chevron-icon" dangerouslySetInnerHTML={{ __html: chevronIconSvg }}></div>
          </a>

          {isSelected && <div className="main-menu__bridge"></div>}
        </>
      ) : (
        <a className={className} href={menuItem.href}>
          {menuItem.text}
        </a>
      )}

      {menuItem.hasChildren && (
        <SubMenuItems
          isSelected={isSelected}
          menuItems={menuItem.children}
          parentItem={menuItem}
          ariaControlsId={id}
          arrowIconSvg={arrowIconSvg}
        />
      )}
    </li>
  );
};
